import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import './plugins/socketio'
import './filters'
import axios from 'axios';

Vue.config.productionTip = false
axios.defaults.baseURL = process.env.VUE_APP_API_URL

import contenteditable from 'vue-contenteditable'
Vue.use(contenteditable)

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')