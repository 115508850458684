import Vue from 'vue'

import {
  format,
  parseISO
} from 'date-fns'
import enUS from 'date-fns/locale/en-US'

Vue.filter('datetime', function (value) {
  if (value) {
    return format(parseISO(String(value)), 'HH:mm:ss - dd MMMM yyyy', {
      locale: enUS
    })
  }
})

Vue.filter('date', function (value) {
  if (value) {
    return format(parseISO(String(value)), 'dd MMMM yyyy', {
      locale: enUS
    })
  }
})

Vue.filter('time', function (value) {
  if (value) {
    return format(parseISO(String(value)), 'HH:mm:ss', {
      locale: enUS
    })
  }
})