<template>
  <v-container>
    <Lobby />
    <Lobbies />
  </v-container>
</template>

<script>
// @ is an alias to /src
import Lobby from "@/components/Lobby.vue";
import Lobbies from "@/components/Lobbies.vue";

export default {
  components: {
    Lobby,
    Lobbies,
  },
};
</script>
