<template>
  <v-row>
    <v-col cols="12">
      <template>
        <v-row justify="center">
          <v-dialog v-model="dialog" persistent max-width="290" @keydown.enter.stop="validate" @keydown.esc="dialog=false" >
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on">
                <v-icon size="14" class="mr-2">fas fa-person-booth</v-icon>
                Create a lobby
              </v-btn>
            </template>
            <v-card>
              <v-card-title class="headline pink darken-1">
                Lobby Creation
              </v-card-title>
              <v-card-text>
                <v-form ref="form" lazy-validation>
                  <v-row class="mt-3">
                    <v-text-field
                      v-model="name"
                      :rules="rules.name"
                      label="Lobby Name"
                      required
                    >
                    </v-text-field>
                  </v-row>
                  <v-row>
                    <v-text-field
                      v-model="password"
                      :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                      :rules="[
                        rules.password.required,
                        rules.password.min,
                        rules.password.max,
                      ]"
                      :type="show ? 'text' : 'password'"
                      name="password"
                      label="Password"
                      counter
                      @click:append="show = !show"
                    ></v-text-field>
                  </v-row>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn 
                    color="red darken-1" 
                    text @click="dialog = false">
                  Cancel
                </v-btn>
                <v-btn
                  color="green darken-1"
                  text
                  @click="validate"
                  class="mr-4"
                >
                  Create
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </template>
    </v-col>
  </v-row>
</template>

<script>
import {
  uniqueNamesGenerator,
  adjectives,
  colors,
  animals,
} from "unique-names-generator";
import axios from "axios";

export default {
  data() {
    return {
      dialog: false,
      name: "",
      show: false,
      password: "",
      rules: {
        name: [
          (v) => !!v || "Name is required",
          (v) =>
            v.length <= 50 || "Lobby's name must be composed of 7 characters",
        ],
        password: {
          required: (value) => !!value || "Required.",
          min: (v) => v.length >= 3 || "Min 3 characters",
          max: (v) => v.length <= 50 || "Max 50 characters",
          emailMatch: () => `The email and password you entered don't match`,
        },
      },
    };
  },
  watch: {
    dialog(visible) {
      if (visible) {
        this.reset();
      }
    },
  },
  methods: {
    validate() {
      if (this.$refs.form.validate()) this.createLobby();
    },
    createLobby() {
      axios
        .post("/lobbies", {
          name: this.name,
          password: this.password,
        })
        .then((response) => {
          this.$router.push({
            name: "LobbyDetail",
            params: { lobbyId: response.data.id },
          });
        });
    },
    reset() {
      this.name = uniqueNamesGenerator({
        dictionaries: [adjectives, colors, animals],
        style: "capital",
        separator: " ",
      });
      this.password = "";
    },
  },
};
</script>

<style scoped>
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
</style>
