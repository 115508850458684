import { render, staticRenderFns } from "./Lobbies.vue?vue&type=template&id=7c8ba606&scoped=true&"
import script from "./Lobbies.vue?vue&type=script&lang=js&"
export * from "./Lobbies.vue?vue&type=script&lang=js&"
import style0 from "./Lobbies.vue?vue&type=style&index=0&id=7c8ba606&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7c8ba606",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VCard,VCardTitle,VChip,VCol,VContainer,VDataTable,VIcon,VRow,VTextField})
