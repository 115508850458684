import Vue from 'vue'
import Vuex from 'vuex'
import notifications from './modules/notifications'
import dashboard from './modules/dashboard'
import lobbies from './modules/lobbies'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    notifications,
    dashboard,
    lobbies
  }
})