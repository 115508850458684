<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="12">
        <v-card>
          <v-card-title class="pink darken-1 justify-center"
            ><v-icon size="16" class="mr-2">fas fa-chess</v-icon
            >Lobby</v-card-title
          >
          <v-card-text>
            <v-row wrap class="mt-3">
              <v-col cols="12">
                <LobbyCreate />
              </v-col>
              <v-col cols="12">
                <v-btn
                  ><v-icon size="14" class="mr-2">fas fa-terminal</v-icon>
                  Connect with code</v-btn
                >
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import LobbyCreate from "./LobbyCreate.vue";
export default {
  data: () => ({
    showLobbyPanel: false,
  }),
  components: { LobbyCreate },
};
</script>

<style scoped>
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
</style>
