<template>
  <v-row>
    <v-col cols="12">
      <template>
        <v-row justify="center">
          <v-dialog
            v-model="dialog"
            persistent
            max-width="290"
            @keydown.enter.stop="validate"
            @keydown.esc="dialog = false"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on">
                <v-icon size="14" class="mr-2">fas fa-signature</v-icon>
                Change username
              </v-btn>
            </template>
            <v-card>
              <v-card-title class="headline pink darken-1">
                Change username
              </v-card-title>
              <v-card-text>
                <v-form ref="form" lazy-validation>
                  <v-row class="mt-3">
                    <v-text-field
                      v-model="newUsername"
                      :rules="rules.username"
                      label="Username"
                      required
                    >
                    </v-text-field>
                  </v-row>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red darken-1" text @click="dialog = false">
                  Cancel
                </v-btn>
                <v-btn
                  color="green darken-1"
                  text
                  @click="validate"
                  class="mr-4"
                >
                  Change
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </template>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import {
  uniqueNamesGenerator,
  adjectives,
  colors,
  animals,
} from "unique-names-generator";

export default {
  data() {
    return {
      dialog: false,
      newUsername: "",
      show: false,
      rules: {
        username: [
          (v) => !!v || "Username is required",
          (v) => v.length <= 50 || "Username must be less of 50 characters",
        ],
      },
    };
  },
  watch: {
    dialog(visible) {
      if (visible) {
        this.reset();
      }
    },
  },
  computed: {
    ...mapGetters({
      username: "dashboard/username",
    }),
  },
  methods: {
    validate() {
      if (this.$refs.form.validate()) this.setUsername();
    },
    setUsername() {
      this.$store.commit("dashboard/setUsername", this.newUsername);
      location.reload();
    },
    reset() {
      this.name = uniqueNamesGenerator({
        dictionaries: [adjectives, colors, animals],
        style: "capital",
        separator: " ",
      });
      this.password = "";
    },
  },
  mounted() {
    this.newUsername = this.username;
  },
};
</script>

<style scoped>
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
</style>
