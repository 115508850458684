<template>
  <v-container fluid>
    <v-row class="text-center">
      <v-col cols="12">
        <v-icon v-if="loading">fas fa-circle-notch fa-spin</v-icon>
        <v-row>
          <v-col cols="12" md="9">
            <v-row>
              <v-col cols="12" md="2" class="pb-0">
                <v-card v-if="!loading">
                  <BoardImage
                    v-show="$vuetify.breakpoint.smAndDown"
                    rotation="horizontal"
                  />
                  <BoardImage
                    v-show="!$vuetify.breakpoint.smAndDown"
                    rotation="vertical"
                  />
                </v-card>
              </v-col>
              <v-col
                cols="12"
                md="10"
                :class="{ 'pl-0 pr-0': $vuetify.breakpoint.mdAndUp }"
              >
                <v-card v-if="!loading" class="mb-3">
                  <v-card-title class="pink darken-1 justify-center"
                    ><v-icon size="16" class="mr-2">fas fa-chess</v-icon
                    >{{ lobby.name }}</v-card-title
                  >

                  <v-card-text class="pt-4">
                    <v-container fill-height>
                      <v-row align="center">
                        {{ lobby.state.phase }}
                      </v-row>
                      <v-row align="center" v-if="phraseVisible">
                        <v-col cols="12" class="pl-10 pr-10 pt-4 pb-7">
                          <span class="quote ligatures">
                            <contenteditable
                              tag="span"
                              :contenteditable="phraseEditable"
                              v-model="lobby.state.phrase"
                              :noNL="true"
                              :noHTML="true"
                            ></contenteditable>
                          </span>
                        </v-col>
                      </v-row>
                      <v-row
                        align="center"
                        justify="center"
                        v-if="dropAreaVisible"
                      >
                        <v-col cols="12 mb-5">
                          <drop
                            class="drop-area"
                            @drop="onDropCard"
                            :mode="dropMode"
                            :class="{ empty: !proposedCard }"
                          >
                            <drag
                              :disabled="shouldDisableDragnDrop"
                              :data="proposedCard"
                              :model="proposedCard"
                              @dragstart.prevent
                              class="item"
                              @cut="removeProposedCard"
                            >
                              <img
                                class="card"
                                v-if="proposedCard"
                                :src="proposedCard"
                              />
                            </drag>
                          </drop>
                        </v-col>
                      </v-row>
                      <v-row
                        v-if="chooseCardVisible"
                        align="center"
                        justify="center"
                        class="mb-5"
                      >
                        <span
                          class="quote"
                          v-if="lobby.state.currentMaster !== username"
                        >
                          Choose a card
                        </span>
                        <span class="quote" v-else>
                          Cards on table
                        </span>
                      </v-row>
                      <v-row v-if="chooseCardVisible" class="mb-5">
                        <div
                          v-for="card in lobby.state.cardsToChoose"
                          :key="card"
                          class="item"
                          @click="
                            lobby.state.currentMaster !== username
                              ? (chosenCard = card)
                              : null
                          "
                        >
                          <img
                            class="card scale-on-hover"
                            :class="{ 'card-selected': chosenCard === card }"
                            :src="card"
                          />
                        </div>
                      </v-row>
                      <v-row align="center" justify="center">
                        <v-col cols="12">
                          <span
                            class="quote"
                            v-if="
                              lobby.state.phase ===
                                'master-choosing-phrase-and-card' &&
                              lobby.state.currentMaster !== this.username
                            "
                            >Waiting for
                            <span
                              :style="{
                                color: getPlayerColor(
                                  lobby.state.currentMaster
                                ),
                              }"
                              >{{ lobby.state.currentMaster }}</span
                            >
                            to choose a phrase and a card...</span
                          >
                          <span class="quote" v-if="waitingMessageVisible"
                            >Waiting for
                            <span
                              v-if="
                                lobby.state.phase === 'players-placing-cards'
                              "
                            >
                              <span
                                v-for="user in missingPlayersProposals"
                                :key="user"
                                ><span
                                  :style="{
                                    color: getPlayerColor(user),
                                  }"
                                  >{{ user }}</span
                                >,
                              </span>
                            </span>
                            <span
                              v-if="
                                lobby.state.phase === 'players-choosing-cards'
                              "
                            >
                              <span
                                v-for="user in missingPlayersChoices"
                                :key="user"
                                ><span
                                  :style="{
                                    color: getPlayerColor(user),
                                  }"
                                  >{{ user }}</span
                                >,
                              </span>
                            </span>
                            to
                            {{
                              lobby.state.phase === "players-choosing-cards"
                                ? "choose"
                                : "play"
                            }}
                            a card...</span
                          >
                          <v-row
                            align="center"
                            justify="center"
                            class="mb-5"
                            v-if="
                              lobby.state.phase === 'assign-points-to-players'
                            "
                          >
                            <v-col cols="12"
                              ><span class="quote"
                                >The Master's card was</span
                              ></v-col
                            >
                            <div class="item">
                              <img
                                :src="lobby.state.phraseCard"
                                class="card scale-on-hover"
                              />
                            </div>
                            <v-col cols="12">
                              <span
                                v-if="lobby.state.scoreMessage"
                                class="quote"
                                >{{ lobby.state.scoreMessage }}<br /><br
                              /></span>
                              <span v-else class="quote">
                                <span
                                  v-for="player in lobby.state
                                    .playersThatGuessedMastersCard"
                                  :key="player"
                                >
                                  <span
                                    :style="{
                                      color: getPlayerColor(player),
                                    }"
                                    >{{ player }}</span
                                  >,
                                </span>
                                guessed the Master's card!<br /><br />
                              </span>
                              <span class="quote"> Points: </span><br />
                              <span
                                class="quote"
                                v-for="(data, player) in lobby.state
                                  .playersScore"
                                :key="player"
                              >
                                <span
                                  :style="{
                                    color: getPlayerColor(player),
                                  }"
                                  >{{ player }}</span
                                >
                                +{{ data.score }}
                                <img
                                  v-for="card in data.cards"
                                  :key="card"
                                  :src="card"
                                  class="mini-card scale-on-hover"
                                  height="25px" />
                                <br
                              /></span>
                            </v-col>
                            <v-col
                              v-if="lobby.state.currentMaster !== username"
                              cols="12"
                              ><span class="quote"
                                >Waiting for
                                <span
                                  :style="{
                                    color: getPlayerColor(
                                      lobby.state.currentMaster
                                    ),
                                  }"
                                  >{{ lobby.state.currentMaster }}</span
                                >
                                to start new turn!</span
                              ></v-col
                            >
                          </v-row>
                        </v-col>
                      </v-row>
                      <v-row
                        align="center"
                        justify="center"
                        v-if="confirmButtonVisible"
                      >
                        <v-col cols="12">
                          <v-btn
                            :disabled="confirmButtonDisabled"
                            @click="confirm()"
                            >{{
                              lobby.state.phase !== "assign-points-to-players"
                                ? "Confirm"
                                : "Next turn"
                            }}</v-btn
                          >
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                </v-card>
                <v-card v-if="!loading">
                  <v-card-title class="pink darken-1 justify-center"
                    >Your hand</v-card-title
                  >
                  <v-card-text>
                    <drop-list
                      :items="playerCards"
                      class="row no-gutters justify-center"
                      @reorder="$event.apply(playerCards)"
                      @insert="onCardPick"
                      mode="cut"
                    >
                      <template v-slot:item="{ item }">
                        <drag
                          class="item"
                          :key="item"
                          :model="item"
                          :data="item"
                          @dragstart.prevent
                          @cut="playCard(item)"
                          ><img class="card scale-on-hover" :src="item" /><v-btn
                            v-if="selectCardButtonVisible"
                            @click="selectCardFromHand(item)"
                            >Select</v-btn
                          ></drag
                        >
                      </template>
                      <template v-slot:feedback="{ data }">
                        <div class="item feedback" :key="data">
                          <img class="card" :src="data" />
                        </div>
                      </template>
                    </drop-list>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="3">
            <v-card class="mb-3">
              <v-card-title class="pink darken-1 justify-center"
                ><v-icon size="16" class="mr-2">far fa-users</v-icon
                >Users</v-card-title
              >

              <v-list v-if="!loading">
                <v-list-item
                  two-line
                  v-for="player in lobby.players"
                  :key="player.username"
                >
                  <v-list-item-content>
                    <v-list-item-title :style="{ color: player.color }">{{
                      player.username
                    }}</v-list-item-title>
                    <v-list-item-subtitle>{{
                      player.score
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card>
            <v-card class="mb-3">
              <v-card-title class="pink darken-1 justify-center"
                ><v-icon size="16" class="mr-2">far fa-users</v-icon
                >Chat</v-card-title
              >

              <v-list ref="chat" class="overflow-y-auto" max-height="300">
                <v-list-item
                  two-line
                  v-for="(message, index) in lobby.chat"
                  :key="index"
                >
                  <v-list-item-content>
                    <v-list-item-title
                      :style="{ color: getPlayerColor(message.username) }"
                      >{{ message.username }}</v-list-item-title
                    >
                    <v-list-item-subtitle>{{
                      message.message
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>

              <v-card-text>
                <v-text-field
                  v-model="chatMessage"
                  @keydown.enter="sendChatMessage"
                ></v-text-field>
                <v-btn @click="sendChatMessage">Send</v-btn>
              </v-card-text>
            </v-card>
            <v-card>
              <v-card-title class="pink darken-1 justify-center"
                ><v-icon size="16" class="mr-2">far fa-users</v-icon>Switch
                device</v-card-title
              >
              <v-card-text class="pt-5">
                <div class="mb-5">
                  Scan the following QRCode to play with your user on another
                  device
                </div>
                <qrcode-vue
                  :value="switchDeviceUrl"
                  :size="200"
                  render-as="svg"
                  foreground="#d81b60"
                  background="#1E1E1E"
                ></qrcode-vue>
                <div class="mt-3">
                  Or
                  <a :href="switchDeviceUrl" target="_blank">copy this link</a>!
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import BoardImage from "./BoardImage.vue";
import axios from "axios";
import { Drag, DropList, Drop } from "vue-easy-dnd";
import QrcodeVue from "qrcode.vue";
import { Base64 } from "js-base64";
import _ from "lodash";

export default {
  props: {
    lobbyId: { type: String, required: true, default: undefined },
  },
  data: () => ({
    chatMessage: "",
    loading: true,
    showLobbyPanel: false,
    lobby: { chat: [], players: {}, state: { phrase: "" } },
    users: [],
    proposedCard: undefined,
    chosenCard: undefined,
    playerCards: [],
    dropMode: "cut",
  }),
  computed: {
    ...mapGetters({
      username: "dashboard/username",
    }),
    socketChannels() {
      return [
        { channel: `lobbies/update/{lobbyId}`, listener: this.lobbyListener },
        { channel: `lobbies/{lobbyId}/users`, listener: this.usersListener },
        {
          channel: `lobbies/{lobbyId}/chatMessage`,
          listener: this.chatListener,
        },
        {
          channel: `lobbies/{lobbyId}/playerCards`,
          listener: this.cardsListener,
        },
        {
          channel: `lobbies/{lobbyId}/newTurn`,

          listener: this.newTurnListener,
        },
      ];
    },
    switchDeviceUrl() {
      const secrets = JSON.parse(localStorage.secrets);
      const lobby = _.find(secrets, { lobbyId: this.lobbyId });
      if (lobby) {
        return `http://${location.host}/?quickconnect=${Base64.encode(
          this.username
        )}:${Base64.encode(this.lobbyId)}:${Base64.encode(lobby.secret)}`;
      } else {
        return "";
      }
    },
    lobbyListener() {
      return (payload) => {
        if (!payload.state.phrase) {
          payload.state.phrase = "";
        }
        this.lobby = payload;
      };
    },
    usersListener() {
      return (payload) => {
        this.users = payload;
      };
    },
    chatListener() {
      return (payload) => {
        this.lobby.chat.push(payload);
        this.scrollChat();
      };
    },
    cardsListener() {
      return (payload) => {
        this.playerCards = payload;
      };
    },
    newTurnListener() {
      return () => {
        this.proposedCard = undefined;
        this.chosenCard = undefined;
      };
    },
    isMaster() {
      return this.lobby.state.currentMaster === this.username;
    },
    hasProposedCard() {
      return this.lobby.state.playersProposals[this.username];
    },
    hasChosenCard() {
      return this.lobby.state.playersChoices[this.username];
    },
    phraseEditable() {
      return (
        this.lobby.state.phase === "master-choosing-phrase-and-card" &&
        this.isMaster
      );
    },
    chooseCardVisible() {
      return (
        this.lobby.state.phase === "players-choosing-cards" &&
        (this.isMaster || !this.hasChosenCard)
      );
    },
    phraseVisible() {
      return (
        (this.lobby.state.phase === "master-choosing-phrase-and-card" &&
          this.isMaster) ||
        this.lobby.state.phase !== "master-choosing-phrase-and-card"
      );
    },
    dropAreaVisible() {
      return (
        (this.lobby.state.phase === "master-choosing-phrase-and-card" &&
          this.isMaster) ||
        (this.lobby.state.phase === "players-placing-cards" &&
          !this.isMaster &&
          !this.hasProposedCard)
      );
    },
    confirmButtonVisible() {
      return (
        (this.lobby.state.phase === "master-choosing-phrase-and-card" &&
          this.isMaster) ||
        (this.lobby.state.phase === "players-placing-cards" &&
          !this.isMaster &&
          !this.hasProposedCard) ||
        (this.lobby.state.phase === "players-choosing-cards" &&
          !this.isMaster &&
          !this.hasChosenCard) ||
        (this.lobby.state.phase === "assign-points-to-players" && this.isMaster)
      );
    },
    selectCardButtonVisible() {
      return (
        (this.lobby.state.phase === "master-choosing-phrase-and-card" &&
          this.isMaster) ||
        (this.lobby.state.phase === "players-placing-cards" &&
          !this.isMaster &&
          !this.hasProposedCard) ||
        (this.lobby.state.phase === "players-choosing-cards" &&
          !this.isMaster &&
          !this.hasChosenCard)
      );
    },
    confirmButtonDisabled() {
      return (
        (this.lobby.state.phase === "master-choosing-phrase-and-card" &&
          (!this.lobby.state.phrase || !this.proposedCard)) ||
        (this.lobby.state.phase === "players-placing-cards" &&
          !this.isMaster &&
          !this.proposedCard) ||
        (this.lobby.state.phase === "players-choosing-cards" &&
          !this.isMaster &&
          !this.chosenCard) ||
        (this.lobby.state.phase === "assign-points-to-players" &&
          !this.isMaster)
      );
    },
    waitingMessageVisible() {
      return (
        (this.lobby.state.phase === "players-placing-cards" &&
          (this.isMaster || this.hasProposedCard)) ||
        (this.lobby.state.phase === "players-choosing-cards" &&
          (this.isMaster || this.hasChosenCard))
      );
    },
    shouldDisableDragnDrop() {
      return (
        (this.lobby.state.phase === "players-placing-cards" &&
          (this.isMaster || this.hasProposedCard)) ||
        (this.lobby.state.phase === "players-choosing-cards" &&
          (this.isMaster || this.hasChosenCard))
      );
    },
    missingPlayersProposals() {
      return _.difference(Object.keys(this.lobby.players), [
        ...Object.keys(this.lobby.state.playersProposals),
        this.lobby.state.currentMaster,
      ]);
    },
    missingPlayersChoices() {
      return _.difference(Object.keys(this.lobby.players), [
        ...Object.keys(this.lobby.state.playersChoices),
        this.lobby.state.currentMaster,
      ]);
    },
  },
  components: {
    BoardImage,
    DropList,
    Drop,
    Drag,
    QrcodeVue,
  },
  watch: {
    lobbyId: function (newValue, oldValue) {
      this.handleSocketChannels(newValue, "on");
      this.handleSocketChannels(oldValue, "off");
      this.refresh();
    },
  },
  methods: {
    handleSocketChannels(lobbyId, mode) {
      _.each(this.socketChannels, (socketChannel) => {
        const channel = socketChannel.channel.replace("{lobbyId}", lobbyId);
        if (mode === "on") {
          this.$socket.client.on(channel, socketChannel.listener);
        } else {
          this.$socket.client.off(channel, socketChannel.listener);
        }
      });
    },
    refresh() {
      this.loading = true;
      axios.get(`lobbies/${this.lobbyId}`).then((response) => {
        if (!response.data.state.phrase) {
          response.data.state.phrase = "";
        }
        this.lobby = response.data;
        this.loading = false;
        this.scrollChat();
      });
    },
    sendChatMessage() {
      if (this.chatMessage) {
        this.$socket.client.emit("sendChatMessage", {
          lobbyId: this.lobbyId,
          message: this.chatMessage,
        });
        this.chatMessage = "";
      }
    },
    getPlayerColor(username) {
      return _.get(this.lobby.players, `${username}.color`, "#fdfdfd");
    },
    scrollChat() {
      if (this.$refs.chat) {
        const chat = this.$refs.chat.$el;
        // We need to wait for UI update before scroll to bottom
        this.$nextTick(() => {
          chat.scrollTop = chat.scrollHeight;
        });
      }
    },
    selectCardFromHand(card) {
      if (this.proposedCard) {
        this.playerCards.splice(
          this.playerCards.indexOf(card),
          0,
          this.proposedCard
        );
      }
      this.proposedCard = card;
      this.playerCards.splice(this.playerCards.indexOf(card), 1);
    },
    onDropCard(event) {
      // If there is already a card in the drop area, put back in hand swapping it with the selected card
      if (this.proposedCard) {
        this.playerCards.splice(
          this.playerCards.indexOf(event.data),
          0,
          this.proposedCard
        );
      }
      this.proposedCard = event.data;
    },
    onCardPick(event) {
      this.playerCards.splice(event.index, 0, event.data);
    },
    removeProposedCard() {
      this.proposedCard = undefined;
    },
    playCard(card) {
      this.playerCards.splice(this.playerCards.indexOf(card), 1);
    },
    confirm() {
      this.$socket.client.emit("updateGameState", {
        lobbyId: this.lobbyId,
        phrase: this.lobby.state.phrase,
        phraseCard: this.proposedCard,
        proposedCard: this.proposedCard,
        chosenCard: this.chosenCard,
      });
    },
  },
  mounted() {
    this.handleSocketChannels(this.lobbyId, "on");
    this.$socket.client.emit("joinLobby", this.lobbyId);
    this.refresh();
  },
  beforeDestroy() {
    this.handleSocketChannels(this.lobbyId, "off");
    this.$socket.client.emit("leaveLobby", this.lobbyId);
  },
};
</script>

<style scoped>
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}

.item {
  padding: 10px;
  margin: 0 auto;
}

.card {
  width: 160px;
  height: 240px;
  display: flex;
  flex: 1;
  border-radius: 5px;
  border: 2px solid dimgray;
}

.mini-card {
  border-radius: 3px;
  border: 1px solid dimgray;
}

.card-selected {
  border: 2px solid #f06292;
  -webkit-animation: glow 1s ease-in-out infinite alternate;
  -moz-animation: glow 1s ease-in-out infinite alternate;
  animation: glow 1s ease-in-out infinite alternate;
}

@keyframes glow {
  from {
    box-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px #d81b60, 0 0 20px #d81b60,
      0 0 25px #d81b60, 0 0 30px #d81b60, 0 0 40px #d81b60;
  }
  to {
    box-shadow: 0 0 2px #fff, 0 0 5px #f06292, 0 0 10px #f06292,
      0 0 15px #f06292, 0 0 20px #f06292, 0 0 30px #f06292, 0 0 40px #f06292;
  }
}

.card.scale-on-hover:hover {
  -webkit-transform: scale(1.5);
  -moz-transform: scale(1.5);
  -ms-transform: scale(1.5);
  -o-transform: scale(1.5);
  transform: scale(1.5);
  z-index: 1;
}

.mini-card.scale-on-hover:hover {
  -webkit-transform: scale(5);
  -moz-transform: scale(5);
  -ms-transform: scale(5);
  -o-transform: scale(5);
  transform: scale(5);
  z-index: 1;
}

@import url("https://fonts.googleapis.com/css?family=Special+Elite");

.quote {
  font-family: "Special Elite", cursive;
  font-weight: 100;
  font-size: 2rem;
  max-width: 600px;
  line-height: 1.4;
  position: relative;
  margin: 0;
  padding: 0.5rem;
  color: rgb(245, 245, 245);
}

.quote:before,
.quote:after {
  position: absolute;
  color: #f1efe6;
  font-size: 5rem;
  width: 4rem;
  height: 4rem;
}

.quote.ligatures:before {
  content: "“";
  left: -3.7rem;
  top: -1rem;
}

.quote.ligatures:after {
  content: "”";
  right: -3.7rem;
  bottom: -1rem;
}

.quote [contenteditable="true"]:empty:before {
  content: "Insert phrase here";
  pointer-events: none;
  color: #7e7e7e;
}

.drop-area {
  border: 4px dashed #7e7e7e;
  margin: 0 auto;
  border-radius: 10px;
  width: 188px;
  height: 268px;
}

.drop-area.drop-allowed {
  border: 4px dashed #f06292;
}

.drop-area.drop-allowed.empty:before {
  color: #f06292;
}

.drop-area.drop-in {
  border: 4px dashed #d81b60;
}

.drop-area.drop-in.empty:before {
  color: #d81b60;
}

.drop-area.empty:before {
  display: flex;
  color: #7e7e7e;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  font-family: "Special Elite", cursive;
  font-weight: 100;
  font-size: 1.5rem;
  content: "Drop card here";
}

.player-cards {
  display: flex;
  flex-wrap: wrap;
  flex: 1 1 auto;
}

.list-enter,
.list-leave-to {
  opacity: 0;
}

.list-leave-active {
  position: absolute;
}
</style>
