<template>
  <v-img
    v-if="rotation === 'vertical'"
    src="@/assets/board-vertical.jpg"
  ></v-img>
  <v-img contain v-else src="@/assets/board-horizontal.jpg"></v-img>
</template>

<script>
export default {
  props: {
    rotation: { type: String, default: "vertical" },
  },
  data: () => ({}),
};
</script>
