<template>
  <v-app>
    <v-app-bar app color="pink darken-4" dark>
      <div class="d-flex align-center" @click="$router.push({ name: 'Home' })">
        <span class="font-weight-light">W</span
        ><span class="font-weight-black">I</span
        ><span class="font-weight-light">B</span
        ><span class="font-weight-black">XIT</span>
      </div>

      <v-spacer></v-spacer>

      <v-slide-x-transition group>
        <v-avatar
          v-for="user in avatarUsers"
          :key="user.name"
          :first="user === 1"
          :color="user.color"
          >{{ user.shortname }}</v-avatar
        >
      </v-slide-x-transition>
      {{ username }}
    </v-app-bar>

    <v-main>
      <router-view></router-view>
      <v-row no-gutters class="text-center">
        <v-col cols="12">
          <span v-if="$socket.connected" class="green--text"
            ><v-icon class="mr-1" color="green" x-small
              >far fa-check-circle</v-icon
            >
            Connected</span
          >
          <span v-else class="red--text"
            ><v-icon class="mr-1" color="red" x-small
              >far fa-times-circle</v-icon
            >Disconnected</span
          >
        </v-col>
      </v-row>
    </v-main>

    <v-snackbar
      v-for="(notification, index) in notifications"
      :key="index"
      v-model="notification.active"
      :bottom="true"
      :right="true"
      :timeout="notification.timeout"
    >
      {{ notification.text }}
      <v-btn dark text @click="notification.active = false">Close</v-btn>
    </v-snackbar>
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";
import toMaterialStyle from "material-color-hash";
import _ from "lodash";
import { Base64 } from "js-base64";
import {
  uniqueNamesGenerator,
  colors,
  animals,
  adjectives,
} from "unique-names-generator";

export default {
  name: "App",
  components: {},
  computed: {
    ...mapGetters({
      notifications: "notifications/notifications",
      connectedUsersCount: "dashboard/connectedUsersCount",
      username: "dashboard/username",
    }),
    avatarUsers() {
      return this.connectedUsersCount
        ? _.map(this.connectedUsersCount, (user) => ({
            ...user,
            shortname: _.truncate(user.name, { length: 4, omission: "" }),
            color: toMaterialStyle(user.name, { shade: 500 }).backgroundColor,
          }))
        : [];
    },
  },
  sockets: {
    connect() {
      this.$store.commit("notifications/push", { text: "Connesso!" });
      this.$socket.client.emit("setUsername", this.username);
    },
    connectedUsersCount(data) {
      this.$store.commit("dashboard/setConnectedUsersCount", data);
    },
    setRoomPlayerSecret(data) {
      this.setRoomSecret(data);
    },
    message(data) {
      this.$store.commit("notifications/push", data);
    },
  },
  methods: {
    setRoomSecret(data) {
      if (!localStorage.secrets) {
        localStorage.secrets = JSON.stringify([data]);
      } else {
        const secrets = JSON.parse(localStorage.secrets);
        const lobbySecretIndex = _.findIndex(secrets, {
          lobbyId: data.lobbyId,
        });
        if (lobbySecretIndex === -1) {
          secrets.push(data);
        } else {
          secrets[lobbySecretIndex] = data;
        }
        localStorage.secrets = JSON.stringify(secrets);
      }
    },
  },
  created() {
    window.addEventListener("beforeunload", () => {
      this.$socket.emit("disconnect");
    });
    if (this.$route.query.username) {
      this.$store.commit("dashboard/setUsername", this.$route.query.username);
    } else if (localStorage.username) {
      this.$store.commit("dashboard/setUsername", localStorage.username);
    } else {
      const username = uniqueNamesGenerator({
        dictionaries: [adjectives, colors, animals],
        style: "capital",
        separator: " ",
      });
      this.$store.commit("dashboard/setUsername", username);
    }
    if (this.$route.query.quickconnect) {
      let [username, lobbyId, secret] = this.$route.query.quickconnect.split(":");
      if (username && lobbyId && secret) {
        username = Base64.decode(username);
        lobbyId = Base64.decode(lobbyId);
        secret = Base64.decode(secret);
        this.$store.commit("dashboard/setUsername", username);
        this.setRoomSecret({
          lobbyId: lobbyId,
          secret: secret,
        });
        this.$router.push({
          name: "LobbyDetail",
          params: { lobbyId: lobbyId },
        });
      }
    }
  },
  data: () => ({
    //
  }),
};
</script>
