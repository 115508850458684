const state = {
    connectedUsersCount: 0,
    username: undefined
}

// getters
const getters = {
    connectedUsersCount: state => {
        return state.connectedUsersCount
    },
    username: state => {
        return state.username
    },
}

// actions
const actions = {
    setConnectedUsersCount({
        commit
    }, connectedUsersCount) {
        commit('setConnectedUsersCount', connectedUsersCount)
    },
    setUsername({
        commit
    }, username) {
        commit('setConnectedUsersCount', username)
    }
}

// mutations
const mutations = {
    setConnectedUsersCount(state, connectedUsersCount) {
        state.connectedUsersCount = connectedUsersCount
    },
    setUsername(state, username) {
        state.username = username
        if (localStorage.username !== username) {
            localStorage.username = username
        }
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}