var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',{staticClass:"text-center"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-card-title',{staticClass:"pink darken-1 justify-center"},[_vm._v("Lobbies")]),_c('v-data-table',{staticClass:"elevation-1 row-pointer",attrs:{"headers":_vm.headers,"items":_vm.coloredLobbies,"options":_vm.lobbiesTableOptions,"server-items-length":_vm.pagination.rowCount,"loading":_vm.lobbiesLoading,"item-key":"id","multi-sort":"","search":_vm.search},on:{"update:options":function($event){_vm.lobbiesTableOptions=$event},"click:row":_vm.openLobby},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-text-field',{staticClass:"mx-4",attrs:{"label":"Search"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('strong',{style:(("border-bottom: solid 3px " + (item.lobbyTitleColor)))},[_vm._v(_vm._s(item.name))])]}},{key:"item.code",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"dark":""}},[_c('strong',[_vm._v(_vm._s(item.code))])])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"label":"","dark":"","color":_vm.statusColor(item.status)}},[_c('strong',[_vm._v(_vm._s(item.status))])])]}},{key:"item.players",fn:function(ref){
var item = ref.item;
return [_c('strong',[_vm._v(_vm._s(item.players.length))])]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_c('strong',[_c('v-icon',{staticClass:"mr-1",attrs:{"size":"12"}},[_vm._v("far fa-clock")]),_vm._v(_vm._s(_vm._f("time")(item.created_at))+" "),_c('v-icon',{staticClass:"ml-1 mr-1",attrs:{"size":"12"}},[_vm._v("far fa-calendar-alt")]),_vm._v(" "+_vm._s(_vm._f("date")(item.created_at)))],1)]}}])})],1)],1)],1),_c('v-row',{staticClass:"text-center mt-5",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('UsernameChange')],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }