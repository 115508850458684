const state = {
    notifications: []
}

// getters
const getters = {
    notifications: state => {
        return state.notifications
    }
}

// actions
const actions = {
    push({
        commit
    }, notification) {
        commit('push', notification)
    }
}

// mutations
const mutations = {
    push(state, notification) {
        state.notifications.push({
            text: notification.text,
            timeout: notification.timeout || 6000,
            active: true
        })
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
