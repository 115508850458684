<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="12">
        <v-icon v-if="loading">fas fa-circle-notch fa-spin</v-icon>
        <v-card v-if="!loading">
          <v-card-title class="pink darken-1 justify-center"
            ><v-icon size="16" class="mr-2">fas fa-chess</v-icon
            >{{ lobby.name }}</v-card-title
          >

          <v-card-text>
            <v-row wrap class="mt-3">
              <v-col cols="12">
                Lobby code
                <v-chip dark>
                  <strong>{{ lobby.code }}</strong>
                </v-chip>
                or <a :href="`/lobby/${lobbyId}`" target="_blank">link</a>
              </v-col>
            </v-row>
          </v-card-text>

          <v-list>
            <v-list-item class="justify-center"
              ><strong>{{ users.length }}</strong>
              <span class="ml-1">players</span></v-list-item
            >
            <v-list-item v-for="player in users" :key="player">
              <v-icon size="15" class="mr-2">fas fa-user</v-icon>
              {{ player }}</v-list-item
            >
          </v-list>
          <v-card-actions class="justify-center">
            <v-btn color="green" @click="play()" :disabled="users.length < 2"
              ><v-icon size="15" class="mr-2">fas fa-play</v-icon>Play</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";

export default {
  props: {
    lobbyId: { type: String, required: true, default: undefined },
  },
  data: () => ({
    loading: true,
    showLobbyPanel: false,
    lobby: undefined,
    users: [],
  }),
  computed: {
    lobbyListener() {
      return (payload) => {
        this.lobby = payload;
      };
    },
    usersListener() {
      return (payload) => {
        this.users = payload;
      };
    },
  },
  components: {},
  watch: {
    lobbyId: function (newValue, oldValue) {
      this.$socket.client.on(`lobbies/update/${newValue}`, this.lobbyListener);
      this.$socket.client.on(`lobbies/${newValue}/users`, this.usersListener);
      this.$socket.client.off(`lobbies/update/${oldValue}`, this.lobbyListener);
      this.$socket.client.off(`lobbies/${oldValue}/users`, this.usersListener);
      this.refresh();
    },
    "lobby.status": function (status) {
      if (status === "ongoing") {
        this.$router.replace({
          name: "Gameboard",
          params: { lobbyId: this.lobbyId },
        });
      }
    },
  },
  methods: {
    refresh() {
      this.loading = true;
      axios.get(`lobbies/${this.lobbyId}`).then((response) => {
        this.lobby = response.data;
        this.loading = false;
      });
    },
    play() {
      axios.put(`lobbies/${this.lobbyId}`).then(() => {
        this.$router.push({
          name: "Gameboard",
          params: { lobbyId: this.lobbyId },
        });
      });
    },
  },
  mounted() {
    this.$socket.client.on(
      `lobbies/update/${this.lobbyId}`,
      this.lobbyListener
    );
    this.$socket.client.on(`lobbies/${this.lobbyId}/users`, this.usersListener);
    this.$socket.client.emit("joinLobby", this.lobbyId);
    this.refresh();
  },
  beforeDestroy() {
    this.$socket.client.off(
      `lobbies/update/${this.lobbyId}`,
      this.lobbyListener
    );
    this.$socket.client.off(
      `lobbies/${this.lobbyId}/users`,
      this.usersListener
    );
    this.$socket.client.emit("leaveLobby", this.lobbyId);
  },
};
</script>

<style scoped>
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
</style>
