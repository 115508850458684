<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="12">
        <v-card>
          <v-card-title class="pink darken-1 justify-center"
            >Lobbies</v-card-title
          >
          <v-data-table
            :headers="headers"
            :items="coloredLobbies"
            :options.sync="lobbiesTableOptions"
            :server-items-length="pagination.rowCount"
            :loading="lobbiesLoading"
            item-key="id"
            multi-sort
            class="elevation-1 row-pointer"
            :search="search"
            @click:row="openLobby"
          >
            <template v-slot:top>
              <v-text-field
                v-model="search"
                label="Search"
                class="mx-4"
              ></v-text-field>
            </template>
            <template v-slot:item.name="{ item }">
              <strong
                :style="`border-bottom: solid 3px ${item.lobbyTitleColor}`"
                >{{ item.name }}</strong
              >
            </template>
            <template v-slot:item.code="{ item }">
              <v-chip dark>
                <strong>{{ item.code }}</strong>
              </v-chip>
            </template>
            <template v-slot:item.status="{ item }">
              <v-chip label dark :color="statusColor(item.status)">
                <strong>{{ item.status }}</strong>
              </v-chip>
            </template>
            <template v-slot:item.players="{ item }">
              <strong>{{ item.players.length }}</strong>
            </template>
            <template v-slot:item.created_at="{ item }">
              <strong
                ><v-icon size="12" class="mr-1">far fa-clock</v-icon
                >{{ item.created_at | time }}
                <v-icon size="12" class="ml-1 mr-1">far fa-calendar-alt</v-icon>
                {{ item.created_at | date }}</strong
              >
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-row no-gutters class="text-center mt-5">
      <v-col cols="12">
        <UsernameChange />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import _ from "lodash";
import toMaterialStyle from "material-color-hash";
import UsernameChange from "./UsernameChange.vue";

export default {
  data: () => ({
    showLobbyPanel: false,
    search: "",
    statusColors: { open: "green darken-1", ongoing: "blue darken-1" },
    headers: [
      { text: "Name", value: "name" },
      { text: "Code", value: "code" },
      { text: "Status", value: "status" },
      { text: "Players", value: "players" },
      { text: "Created at", value: "created_at" },
    ],
    lobbiesTableOptions: {},
  }),
  watch: {
    search() {
      this.lobbiesTableOptions.page = 1;
      this.debounceSearch();
    },
    lobbiesTableOptions: {
      handler() {
        this.refreshLobbies();
      },
      deep: true,
    },
  },
  components: {
    UsernameChange,
  },
  computed: {
    ...mapGetters({
      lobbies: "lobbies/lobbies",
      pagination: "lobbies/pagination",
      lobbiesLoading: "lobbies/loading",
    }),
    debounceSearch() {
      return _.debounce(this.refreshLobbies, 600);
    },
    coloredLobbies() {
      return _.map(this.lobbies, (lobby) => ({
        ...lobby,
        lobbyTitleColor: toMaterialStyle(lobby.name, { shade: 500 })
          .backgroundColor,
      }));
    },
  },
  sockets: {
    "lobbies/create": function () {
      this.$store.dispatch("lobbies/refresh");
    },
  },
  methods: {
    statusColor(status) {
      return _.get(this.statusColors, status, "secondary");
    },
    changePage(page) {
      this.$store.dispatch("lobbies/changePage", page);
    },
    refreshLobbies() {
      this.$store.dispatch("lobbies/changeOptions", {
        ...this.lobbiesTableOptions,
        q: this.search,
      });
    },
    openLobby(lobby) {
      this.$router.push({ name: "LobbyDetail", params: { lobbyId: lobby.id } });
    },
  },
  mounted() {
    this.$store.dispatch("lobbies/refresh");
  },
};
</script>

<style scoped>
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>
