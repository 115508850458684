import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import LobbyDetail from '../components/LobbyDetail.vue'
import Gameboard from '../components/Gameboard.vue'

Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/lobby/:lobbyId',
    name: 'LobbyDetail',
    component: LobbyDetail,
    props: true
  },
  {
    path: '/game/:lobbyId',
    name: 'Gameboard',
    component: Gameboard,
    props: true
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( /* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router